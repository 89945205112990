import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import React from 'react';

const OPEN_LINK_IN_NEW_TAB_INDICATOR = 'newtab#';

interface Props {
  markdown: string;
  markdownOptions?: MarkdownToJSX.Options;
  onLinkClick: (href: string) => void;
}

const MarkdownText = ({
  markdown,
  markdownOptions = {},
  onLinkClick,
}: Props) => {
  const handleOnLinkClick = (href: string) => (event: any) => {
    event.preventDefault();
    if (href) {
      onLinkClick(href);
    }
    return false;
  };

  const Link: React.FC<{
    title: string;
    href: string;
    children: React.ReactNode;
  }> = ({ title, href, children }) => {
    const openInNewTab = href.indexOf(OPEN_LINK_IN_NEW_TAB_INDICATOR) === 0;
    return openInNewTab ? (
      <a
        href={href.slice(OPEN_LINK_IN_NEW_TAB_INDICATOR.length)}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : (
      <a onClick={handleOnLinkClick(href)} title={title} href={href}>
        {children}
      </a>
    );
  };

  return (
    <Markdown
      options={{
        ...markdownOptions,
        overrides: {
          a: Link,
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};

export default MarkdownText;
