import classNames from 'classnames';
import useTranslation from '../../locales/useTranslation';
import styles from './Header.module.css';
import onButtonKeyDownHandler from './onButtonKeyDownHandler';

type Props = {
  onClick: () => void;
};

const CloseChatWindowButton = ({ onClick }: Props) => {
  const translate = useTranslation();

  return (
    <button
      className={classNames(styles.closeButton, 'tooltip--bottom')}
      aria-label={translate('header.close-chat')}
      data-title={translate('header.close-chat')}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onButtonKeyDownHandler(onClick)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
      </svg>
    </button>
  );
};

export default CloseChatWindowButton;
