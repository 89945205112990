import messageStyle from '../../../Message.module.css';
import Text from '../Text';

type Props = {
  text: string;
  commands: string[];
  onLinkClick: (href: string) => void;
};
const TextMessage = ({ text, commands, onLinkClick }: Props) => (
  <div className={messageStyle.bubbleContent}>
    <Text text={text} commands={commands} onLinkClick={onLinkClick} />
  </div>
);

export default TextMessage;
