import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import useTranslation from '../../../locales/useTranslation';
import styles from './PoweredBy.module.css';

const Link: React.FC<{
  title: string;
  href: string;
  children: React.ReactNode;
}> = ({ title, href, children }) => {
  return (
    <a href={href} title={title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const PoweredBy = () => {
  const translate = useTranslation();
  const poweredBy = translate('powered-by');
  return poweredBy && poweredBy.length ? (
    <div className={classNames(styles.container, 'chat__poweredBy')}>
      <Markdown
        options={{
          overrides: {
            a: Link,
          },
        }}
      >
        {poweredBy}
      </Markdown>
    </div>
  ) : null;
};

export default PoweredBy;
