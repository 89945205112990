import {
  Button,
  QuickReply,
  transcriptItemGuestReaction,
} from '@botfabrik/engine-domain';
import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { connect as reduxConnect } from 'react-redux';
import { Action } from 'redux';
import {
  downloadTranscriptPdf,
  exportTranscript,
  handleButtonClick,
  handleLinkClick,
  handleQuickReplyClick,
  handleRatingFromUser,
  requestNLUEvent,
  sendAudioMessageFromUser,
  sendMessageFromUser,
} from '../../actions';
import { restartChat, startChat } from '../../actions/connectionActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { AppDispatch, RootState } from '../../store';
import {
  ChatMessage,
  ConnectionStateEnum,
  ConversationRatingState,
  TranscriptItemReaction,
  UserType,
} from '../../types';
import styles from './Chat.module.css';
import Conversation from './conversation';
import Drawer from './drawer';
import PoweredBy from './powered-by';
import TranscriptMail from './transcript-mail';
import UserInput from './user-input';

interface OwnProps {
  clientName: string;
}

interface StateProps {
  messages: ChatMessage[];
  quickReplies: QuickReply[];
  isBotTyping: boolean;
  drawerContentUrl?: string;
  speechSupport: boolean;
  transcriptExportEnabled: {
    pdf: boolean;
    email: boolean;
  };
  conversationRating: ConversationRatingState;
  showGeneralConditions: boolean;
  commands: string[];
}

interface DispatchProps {
  onRestartChat: (
    clientName: string,
    parsedQuery: Record<string, unknown>
  ) => void;
  onMessageFromUser: (text: string) => void;
  onRate: (rating: number, description: string) => void;
  onActionFromUser: (action: Action) => void;
  onQuickReplyClick: (reply: QuickReply) => void;
  onNluEvent: (event: string) => void;
  onButtonClick: (button: Button) => void;
  onLinkClick: (url: string) => void;
  onAudioRecorded: (blob: Blob) => void;
  onTranscriptExport: (email: string) => void;
  onPdfExport: () => void;
  onTranscriptItemReaction: (
    actionId: string,
    reaction: TranscriptItemReaction
  ) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const ChatComponent = ({
  clientName,
  messages,
  quickReplies,
  isBotTyping,
  drawerContentUrl,
  speechSupport,
  transcriptExportEnabled,
  conversationRating,
  showGeneralConditions,
  commands,
  onMessageFromUser,
  onRate,
  onQuickReplyClick,
  onButtonClick,
  onLinkClick,
  onAudioRecorded,
  onTranscriptExport,
  onPdfExport,
  onTranscriptItemReaction,
}: Props) => {
  const dispatch = useAppDispatch();

  const connectionState = useAppSelector(
    (state) => state.connection.connectionState
  );

  const [showTranscriptMailOverlay, setShowTranscriptMailOverlay] =
    useState(false);
  const [showRatingOverlay, setShowRatingOverlay] = useState(false);

  const anyExportEnabled =
    transcriptExportEnabled.email || transcriptExportEnabled.pdf;

  const numberOfMessagesFromGuest = messages.filter(
    (m) => m.sender.type === UserType.GUEST
  ).length;

  useEffect(() => {
    // start chat once connection is established
    if (connectionState === ConnectionStateEnum.CONNECTED) {
      const parsedQuery = queryString.parse(window.location.search);
      dispatch(startChat(clientName, parsedQuery));
    }
  }, [clientName, connectionState, dispatch]);

  return (
    <div className={styles.container}>
      <div
        className={classNames([
          styles.chatcontent,
          (showTranscriptMailOverlay || showRatingOverlay) &&
            styles.accessabilityHidden,
        ])}
      >
        <Conversation
          messages={messages}
          isBotTyping={isBotTyping}
          showGeneralConditions={showGeneralConditions}
          commands={commands}
          onButtonClick={onButtonClick}
          onLinkClick={onLinkClick}
          onTranscriptItemReaction={onTranscriptItemReaction}
        />
        <UserInput
          isBotTyping={isBotTyping}
          quickReplies={isBotTyping ? [] : quickReplies}
          numberOfMessagesFromGuest={numberOfMessagesFromGuest}
          speechSupport={speechSupport}
          transcriptExportEnabled={anyExportEnabled}
          conversationRating={
            showTranscriptMailOverlay ? { enabled: false } : conversationRating
          }
          showRatingOverlay={showRatingOverlay}
          setShowRatingOverlay={setShowRatingOverlay}
          commands={commands}
          onRate={onRate}
          onQuickReplyClick={onQuickReplyClick}
          onTextInput={onMessageFromUser}
          onAudioRecorded={onAudioRecorded}
          onOpenTranscriptExportModal={() =>
            setShowTranscriptMailOverlay(!showTranscriptMailOverlay)
          }
        />
        <PoweredBy />
      </div>

      {anyExportEnabled && (
        <TranscriptMail
          methods={transcriptExportEnabled}
          visible={showTranscriptMailOverlay}
          onEmailExport={onTranscriptExport}
          onPdfExport={onPdfExport}
          onClose={() => setShowTranscriptMailOverlay(false)}
        />
      )}

      <Drawer contentUrl={drawerContentUrl} />
    </div>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  const {
    messages,
    quickReplies,
    isBotTyping,
    drawerContentUrl,
    speechSupport,
    transcriptExportEnabled,
    conversationRating,
    showGeneralConditions,
    commands,
  } = state.chat;
  return {
    messages,
    quickReplies,
    isBotTyping,
    drawerContentUrl,
    speechSupport,
    transcriptExportEnabled,
    conversationRating,
    showGeneralConditions,
    commands,
  };
};

const bindAction = (dispatch: AppDispatch): DispatchProps => {
  return {
    onRestartChat: (clientName: string, parsedQuery: Record<string, unknown>) =>
      dispatch(restartChat(clientName, parsedQuery)),
    onMessageFromUser: (text: string) => dispatch(sendMessageFromUser(text)),
    onRate: (value: number, feedback: string) =>
      dispatch(handleRatingFromUser(value, feedback)),
    onAudioRecorded: (blob: Blob) => dispatch(sendAudioMessageFromUser(blob)),
    onActionFromUser: (action: Action) => dispatch(action),
    onQuickReplyClick: (reply: QuickReply) =>
      dispatch(handleQuickReplyClick(reply)),
    onNluEvent: (event: string) => dispatch(requestNLUEvent(event)),
    onButtonClick: (button: any) => dispatch(handleButtonClick(button)),
    onLinkClick: (url: any) => dispatch(handleLinkClick(url)),
    onTranscriptExport: (email: string) => dispatch(exportTranscript(email)),
    onPdfExport: () => dispatch(downloadTranscriptPdf()),
    onTranscriptItemReaction: (
      actionId: string,
      reaction: TranscriptItemReaction
    ) => dispatch(transcriptItemGuestReaction(actionId, reaction || null)),
  };
};

export default reduxConnect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  bindAction
)(ChatComponent);
