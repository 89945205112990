import {
  CLOSE_SOURCE_INFOS,
  CLOSE_WINDOW,
  LOAD_SOURCE_INFOS_ERROR,
  LOAD_SOURCE_INFOS_REQUEST,
  LOAD_SOURCE_INFOS_RESPONSE,
} from '.';
import { AppDispatch, RootState } from '../store';

export const RESET_CHAT_STATE = 'RESET_CHAT_STATE';

export const closeChatWindow = () => ({ type: CLOSE_WINDOW });

export const resetChatState = () => ({ type: RESET_CHAT_STATE });

export const loadSourceInfosRequest = (chunkId: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    dispatch({
      type: LOAD_SOURCE_INFOS_REQUEST,
      payload: { chunkId },
    });

    return fetch(`/cms/public/vector-store/chunks/${chunkId}`, {
      headers: { 'Accept-Language': state.intl.locale },
    })
      .then((response) => {
        if (!response.ok) {
          dispatch({ type: LOAD_SOURCE_INFOS_ERROR });
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) =>
        dispatch({ type: LOAD_SOURCE_INFOS_RESPONSE, payload: json })
      );
  };
};

export const closeSourceInfos = () => ({ type: CLOSE_SOURCE_INFOS });
