import { Button as ButtonType } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import Button from '../../../../../components/button/button';
import styles from './DetailMessage.module.css';

type Props = {
  buttons: ButtonType[];
  onButtonClick: (button: ButtonType) => void;
};

const Buttons = ({ buttons, onButtonClick }: Props) => (
  <div className={classNames(styles.buttons, 'chat__bubbleButtons')}>
    {buttons.map((button, index) => (
      <Button
        key={index}
        className={classNames(styles.button, 'chat__bubbleButton')}
        onClick={() => onButtonClick(button)}
      >
        {button.title}
      </Button>
    ))}
  </div>
);

export default Buttons;
