import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { showStartScreen } from '../reducers/startScreenReducer';
import { AppDispatch, RootState } from '../store';
import { resetChatState } from './chatActions';

export const CONNECT = 'CONNECT';
export const CONNECTING = 'CONNECTING';
export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const RECONNECTED = 'RECONNECTED';
export const RESTORE_SESSION_ID = 'RESTORE_SESSION_ID';
export const START = 'START';
export const TERMINATE_SESSION = 'CLEAR_SESSION_ID';

/**
 * Diese Actions sollen nicht ans Backend übermittelt werden (siehe socketMiddleware.ts)
 */
export const INTERNAL_ACTIONS = [
  CONNECT,
  CONNECTING,
  CONNECTED,
  DISCONNECTED,
  RECONNECTED,
  RESTORE_SESSION_ID,
];

/**
 * Erstelle eine Verbindung mit dem Backend Server
 */
export const connect = (
  clientName: string,
  querystrings: Record<string, unknown>
) => {
  return {
    type: CONNECT,
    clientName,
    querystrings,
  };
};

export const restoreSessionId = (sessionId: string) => ({
  type: RESTORE_SESSION_ID,
  sessionId,
});

/**
 * Informiere den Store, dass wir eine Verbindung mit dem Server aufbauen.
 */
export const connecting = () => ({
  type: CONNECTING,
});

/**
 * Informiere den Store, dass wir mit dem Server verbunden sind.
 */
export const connected = () => ({
  type: CONNECTED,
});

/**
 * Informiere den Store, dass die Verbindung mit dem Server getrennt wurde.
 */
export const disconnected = () => ({
  type: DISCONNECTED,
});

/**
 * Informiere den Store, dass die Verbindung mit dem Server wiederhergestellt wurde.
 */
export const reconnected = () => ({
  type: RECONNECTED,
});

export const startChat = (
  clientName: string,
  parsedQuery: Record<string, unknown>
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const connectionState = getState().connection;
    const formValues = getState().startScreen.formValues;

    // get session id
    let sessionId = connectionState.sessionId;
    if (sessionId === undefined) {
      sessionId = uuid();
    }

    // get user id
    let userId = connectionState.userId;
    if (userId === undefined) {
      const userIdCookie =
        Cookies.get('bubble-chat-user-id') ||
        Cookies.get('bubble-chat-userId') ||
        Cookies.get('bf-webclient-userId');
      userId = userIdCookie ? userIdCookie : uuid();
      Cookies.set('bubble-chat-user-id', userId!);
    }

    dispatch({
      type: START,
      payload: {
        sessionId,
        userId,
        clientName,
        querystrings: { ...parsedQuery, ...formValues },
      },
    });
  };
};

export const restartChat = (
  clientName: string,
  parsedQuery: Record<string, unknown>
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const sessionIdToTerminate = getState().connection.sessionId || '';
    dispatch(terminateSession(sessionIdToTerminate));
    dispatch(resetChatState());
    if (getState().startScreen.useStartScreen) {
      dispatch(showStartScreen());
    } else {
      return dispatch(startChat(clientName, parsedQuery));
    }
  };
};

const terminateSession = (sessionId: string) => ({
  type: TERMINATE_SESSION,
  payload: { sessionId },
});
