import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from '../../Message.module.css';

const GuestBubble = ({ children }: PropsWithChildren) => (
  <div
    className={classNames(
      styles.message,
      styles.messageGuest,
      styles.messageGuestFirst,
      styles.messageGuestLast,
      'chat__bubbleContainer',
      'chat__guestBubbleContainer',
      'chat__bubbleContainer--first',
      'chat__bubbleContainer--last'
    )}
  >
    <div
      className={classNames(
        styles.bubble,
        styles.bubbleRight,
        'chat__bubble',
        'chat__guestBubble'
      )}
    >
      <div className={classNames(styles.rightText, styles.bubbleContent)}>
        {children}
      </div>
    </div>
  </div>
);

export default GuestBubble;
