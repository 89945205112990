import { LoadedSourceInfos } from '../../types';
import CloseSourceInfosButton from './close-source-infos-button';
import KnowledgeBaseTypeIcon from './knowledge-base-type-icon';
import styles from './SourceInfos.module.css';

type Props = {
  sourceInfos?: LoadedSourceInfos;
  onClose: () => void;
};

const Header = ({ sourceInfos, onClose }: Props) => {
  return (
    <div className={styles.sourceHeader}>
      <div className={styles.sourceHeaderIcon}>
        {sourceInfos && (
          <KnowledgeBaseTypeIcon type={sourceInfos.knowledgeBaseType} />
        )}
      </div>

      <div>{sourceInfos && <h1>{sourceInfos.title}</h1>}</div>

      <CloseSourceInfosButton onClick={onClose} />
    </div>
  );
};

export default Header;
