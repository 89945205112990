import classNames from 'classnames';
import useTranslation from '../../../../locales/useTranslation';
import styles from './RateInput.module.css';
import Stars from './Stars';

interface Props {
  visible: boolean;
  onRate: (rating: number) => void;
}

const CallToAction = ({ visible, onRate }: Props) => {
  const translate = useTranslation();
  return visible ? (
    <div
      className={classNames(
        styles.callToActionContainer,
        'chat__conversationRating__callToAction'
      )}
    >
      <div
        className={classNames(
          styles.callToActionTitle,
          'chat__conversationRating__callToAction__title'
        )}
      >
        {translate('conversation-rating.call-to-action.title')}
      </div>
      <Stars value={0} size="small" onChange={onRate} />
    </div>
  ) : null;
};

export default CallToAction;
