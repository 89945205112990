import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import styles from './App.module.css';

import { useAppSelector } from './hooks';
import RootScene from './scenes';
import './style/index.css';
import useClientName from './useClientName';

const LARGE_SCREEN_WIDTH = 1024;

const App = () => {
  const clientName = useClientName();
  const locale = useAppSelector((state) => state.intl.locale);
  const message = useAppSelector((state) => state.intl.messages);
  const [isLargeScreen, setIsLargeScreen] = useState(isLargeScreenWidth());

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(isLargeScreenWidth());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <IntlProvider locale={locale} messages={message}>
      <div
        className={classNames([
          styles.app,
          isLargeScreen ? 'chat__large' : 'chat__small',
        ])}
      >
        <RootScene clientName={clientName} />
      </div>
    </IntlProvider>
  );
};

const isLargeScreenWidth = () => window.innerWidth >= LARGE_SCREEN_WIDTH;

export default App;
