import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';

const Button = ({
  className,
  children,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={classNames(styles.button, className)}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
