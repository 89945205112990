import classNames from 'classnames';
import { AnchorHTMLAttributes } from 'react';
import styles from './Button.module.css';

const PrimaryLinkButton = ({
  className,
  children,
  ...rest
}: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a
      className={classNames(styles.button, styles.buttonPrimary, className)}
      type="button"
      {...rest}
    >
      {children}
    </a>
  );
};

export default PrimaryLinkButton;
