import useTranslation from '../../locales/useTranslation';
import onButtonKeyDownHandler from './onButtonKeyDownHandler';

type Props = {
  onClick: () => void;
};

const RestartChatButton = ({ onClick }: Props) => {
  const translate = useTranslation();
  return (
    <button
      className="tooltip--bottom"
      aria-label={translate('header.restart-chat')}
      data-title={translate('header.restart-chat')}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onButtonKeyDownHandler(onClick)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        viewBox="0 0 32 32"
      >
        <path d="M14.506,23.578l-5.511,0l-3.993,3.501l-0.007,0.006c-0.302,0.261 -0.687,0.405 -1.084,0.405c-0.245,-0.001 -0.488,-0.055 -0.711,-0.161c-0.595,-0.277 -0.978,-0.886 -0.974,-1.551l-0,-19.558c-0,-0.939 0.761,-1.711 1.685,-1.711l21.189,-0c0.924,-0 1.685,0.772 1.685,1.711l0,5.104l-1.444,0l-0,-5.104c-0,-0.134 -0.109,-0.244 -0.241,-0.244l-21.189,-0c-0.132,-0 -0.24,0.11 -0.24,0.244l-0,19.558c0.001,0.133 0.109,0.242 0.24,0.242c0.056,-0 0.109,-0.019 0.152,-0.055l4.192,-3.675c0.131,-0.115 0.299,-0.179 0.472,-0.179l5.779,0l-0,1.467Z" />
        <path d="M29.869,20.148c0,4.137 -3.373,7.556 -7.509,7.612l-0.102,-0c-1.943,0.004 -3.814,-0.741 -5.223,-2.078c-0.183,-0.172 -0.286,-0.412 -0.286,-0.664c-0,-0.501 0.412,-0.913 0.913,-0.913c0.233,-0 0.458,0.089 0.627,0.249c1.074,1.014 2.495,1.579 3.971,1.579c3.174,-0 5.785,-2.612 5.785,-5.785c-0,-3.173 -2.611,-5.784 -5.785,-5.784c-1.533,-0 -3.006,0.61 -4.09,1.694c-0.01,0.01 -0.019,0.019 -0.03,0.028l-1.447,1.322l1.302,0c0.502,0 0.914,0.413 0.914,0.914c-0,0.501 -0.412,0.913 -0.914,0.913l-3.653,0c-0.501,0 -0.913,-0.412 -0.913,-0.913l-0,-3.654c-0,-0.501 0.412,-0.913 0.913,-0.913c0.501,0 0.913,0.412 0.913,0.913l0,1.577l1.635,-1.496c1.426,-1.418 3.357,-2.215 5.368,-2.215c4.175,0 7.611,3.436 7.611,7.611c-0,0.001 -0,0.002 -0,0.003Z" />
      </svg>
    </button>
  );
};

export default RestartChatButton;
