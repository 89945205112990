import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import useTranslation from '../../../../locales/useTranslation';
import style from './index.module.css';
import useClickOutsideEventListener from './useClickOutsideEventListener';
import useKeyboardEffect from './useKeyboardEffect';

type Props = {
  text: string;
  commands: string[];
  onSelectCommand: (command: string) => void;
  onVisibilityChange: (visible: boolean) => void;
};

const CommandsDropdown = ({
  text,
  commands,
  onSelectCommand,
  onVisibilityChange,
}: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const translate = useTranslation();
  const [visibleCommands, setVisibleCommands] = useState<string[]>([]);
  const [maxCommandLength, setMaxCommandLength] = useState(
    calcMaxCommandLength(commands)
  );
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleOnSelectCommand = (command: string) =>
    onSelectCommand(`/${command}`);

  useClickOutsideEventListener(dropdownRef, () => setVisibleCommands([]));
  useKeyboardEffect(
    visibleCommands,
    selectedIndex,
    handleOnSelectCommand,
    setSelectedIndex,
    setVisibleCommands
  );

  useEffect(() => {
    setMaxCommandLength(calcMaxCommandLength(commands));
  }, [commands]);

  useEffect(() => {
    const textLength = text.length || 0;
    if (
      textLength === 0 ||
      textLength > maxCommandLength ||
      !text.startsWith('/')
    ) {
      setVisibleCommands([]);
    } else {
      setVisibleCommands(commands.filter(byText(text)));
    }
    setSelectedIndex(0);
  }, [commands, text, maxCommandLength]);

  useEffect(() => {
    onVisibilityChange(!!visibleCommands.length);
  }, [visibleCommands, onVisibilityChange]);

  return visibleCommands.length ? (
    <div ref={dropdownRef} className={style.commandsDropdown}>
      <ul className={style.commands} role="listbox">
        {visibleCommands.map((c, index) => (
          <li
            key={c}
            className={classNames([
              style.command,
              selectedIndex === index && style.commandActive,
            ])}
            role="option"
            onClick={() => handleOnSelectCommand(c)}
            onMouseOver={() => setSelectedIndex(index)}
          >
            <h1>/{c}</h1>
            <p>{translate(`commands.${c}`)}</p>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

const calcMaxCommandLength = (commands: string[]) => {
  return Math.max(...commands.map((c) => c.length + 1));
};

const byText = (text: string) => (command: string) => {
  const commandWithPrefix = `/${command}`;
  return (
    commandWithPrefix.startsWith(text) &&
    commandWithPrefix.length >= text.length
  );
};

export default CommandsDropdown;
