import classNames from 'classnames';
import styles from './Start.module.css';

type Props = {
  type:
    | 'text'
    | 'email'
    | 'tel'
    | 'number'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'week'
    | 'month'
    | 'password';
  name: string;
  label: string;
  defaultValue: string | undefined;
  placeholder: string | undefined;
  required: boolean;
  multiple: boolean;
  min: string | undefined;
  max: string | undefined;
  pattern: string | undefined;
  onChange: (value: string) => void;
};

const Input = ({
  type,
  name,
  label,
  defaultValue,
  placeholder,
  required,
  multiple,
  min,
  max,
  pattern,
  onChange,
}: Props) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <label
      className={classNames(styles.input, 'chat__startScreen__input')}
      htmlFor={name}
    >
      <div className={classNames(styles.inputLabel)}>{label}</div>
      <input
        type={type}
        id={name}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        required={required}
        multiple={multiple}
        min={min}
        max={max}
        pattern={pattern}
        onChange={handleOnChange}
      />
    </label>
  );
};

export default Input;
