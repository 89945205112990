import { configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import getClientName from '../getClientName';
import { initialState } from '../locales';
import reducer from '../reducers';
import { loadState, saveState } from './localStorage';
import socketMiddleware from './socketMiddleware';

const clientName = getClientName();

const preloadedState = { ...loadState(clientName), intl: initialState };

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(socketMiddleware),
  reducer,
  preloadedState,
});

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState(clientName, {
      connection: state.connection,
      startScreen: state.startScreen,
    });
  }, 1000)
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
