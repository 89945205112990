import classNames from 'classnames';
import queryString from 'query-string';
import { HTMLAttributes } from 'react';
import { closeChatWindow } from '../../actions/chatActions';
import { restartChat } from '../../actions/connectionActions';
import { useAppDispatch } from '../../hooks';
import useClientName from '../../useClientName';
import CloseChatWindowButton from './close-chat-window-button';
import styles from './Header.module.css';
import RestartChatButton from './restart-chat-button';

interface Props extends HTMLAttributes<HTMLDivElement> {
  withActions?: boolean;
}

const Header = ({ className, withActions = true, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const clientName = useClientName();

  const handleOnRestartChat = () => {
    const parsedQuery = queryString.parse(window.location.search);
    dispatch(restartChat(clientName, parsedQuery));
  };

  const handleOnCloseChatWindow = () => {
    dispatch(closeChatWindow());
  };

  return (
    <div className={classNames([styles.header, className])} {...rest}>
      <div className={styles.logoContainer}>
        <div className={styles.logo} />
      </div>

      <div className={styles.actions}>
        {withActions && <RestartChatButton onClick={handleOnRestartChat} />}
        <CloseChatWindowButton onClick={handleOnCloseChatWindow} />
      </div>
    </div>
  );
};

export default Header;
