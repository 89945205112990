import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from '../../Message.module.css';

type Props = {
  isFirstMessageBySender: boolean;
  isLastMessageBySender: boolean;
} & PropsWithChildren;

const BotBubble = ({
  isFirstMessageBySender,
  isLastMessageBySender,
  children,
}: Props) => (
  <div
    className={classNames(
      styles.message,
      styles.messageBot,
      isFirstMessageBySender && styles.messageBotFirst,
      isLastMessageBySender && styles.messageBotLast,
      'chat__bubbleContainer',
      'chat__botBubbleContainer',
      isFirstMessageBySender && 'chat__bubbleContainer--first',
      isLastMessageBySender && 'chat__bubbleContainer--last'
    )}
  >
    <img
      className={classNames(styles.avatar, styles.botAvatar)}
      src="bot.svg"
      alt="Bot"
    />
    <div
      className={classNames(
        styles.bubble,
        styles.bubbleLeft,
        'chat__bubble',
        'chat__botBubble'
      )}
    >
      <div className={classNames(styles.leftText, styles.bubbleContent)}>
        {children}
      </div>
    </div>
  </div>
);

export default BotBubble;
