import { useState } from 'react';
import useTranslation from '../../../../locales/useTranslation';
import style from './index.module.css';

const MAX_INITIAL_COMMANDS = 3;

type Props = {
  commands: string[];
  onSelectCommand: (command: string) => void;
};

const CommandsSelections = ({ commands, onSelectCommand }: Props) => {
  const translate = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const handleOnSelectCommand = (command: string) => () => {
    onSelectCommand(`/${command}`);
    setShowMore(false);
  };

  const handleOnShowMore = () => {
    setShowMore(true);
  };

  const handleOnShowLess = () => {
    setShowMore(false);
  };

  const cmds = showMore ? commands : commands.slice(0, MAX_INITIAL_COMMANDS);

  return (
    <div className={style.commandsSelections}>
      {cmds.map((c) => (
        <button onClick={handleOnSelectCommand(c)}>/{c}</button>
      ))}
      {cmds.length < commands.length ? (
        <button
          key="show-more"
          aria-label={translate('commands.show-more.tooltip')}
          data-title={translate('commands.show-more.tooltip')}
          tabIndex={0}
          onClick={handleOnShowMore}
        >
          {translate('commands.show-more.action')}
        </button>
      ) : cmds.length > MAX_INITIAL_COMMANDS ? (
        <button
          key="show-less"
          aria-label={translate('commands.show-less.tooltip')}
          data-title={translate('commands.show-less.tooltip')}
          tabIndex={0}
          onClick={handleOnShowLess}
        >
          {translate('commands.show-less.action')}
        </button>
      ) : null}
    </div>
  );
};

export default CommandsSelections;
