const MarkdownLink: React.FC<{
  title: string;
  href: string;
  children: React.ReactNode;
}> = ({ title, href, children }) => {
  return (
    <a href={href} title={title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default MarkdownLink;
