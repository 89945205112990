import classNames from 'classnames';
import styles from './Start.module.css';

type Props = {
  name: string;
  value: string;
  label: string;
  defaultChecked: boolean;
  required: boolean;
  onChange: (value: string) => void;
};

const Radio = ({
  name,
  value,
  label,
  defaultChecked,
  required,
  onChange,
}: Props) => {
  const id = `${name}-${value}`;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <label
      className={classNames(styles.radio, 'chat__startScreen__radio')}
      htmlFor={id}
    >
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        required={required}
        onChange={handleOnChange}
      />
      <span className={classNames(styles.label)}>{label}</span>
    </label>
  );
};

export default Radio;
