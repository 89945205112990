import PrimaryLinkButton from '../../components/button/primary-link-button';
import styles from './SourceInfos.module.css';

type Props = {
  label: string;
  url: string;
};

const OpenButton = ({ label, url }: Props) => {
  return (
    <PrimaryLinkButton
      className={styles.openSourceButton}
      href={url}
      target="_blank"
    >
      <p>{label}</p>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="#000000"
          viewBox="0 0 256 256"
        >
          <path d="M222,104a6,6,0,0,1-12,0V54.49l-69.75,69.75a6,6,0,0,1-8.48-8.48L201.51,46H152a6,6,0,0,1,0-12h64a6,6,0,0,1,6,6Zm-38,26a6,6,0,0,0-6,6v72a2,2,0,0,1-2,2H48a2,2,0,0,1-2-2V80a2,2,0,0,1,2-2h72a6,6,0,0,0,0-12H48A14,14,0,0,0,34,80V208a14,14,0,0,0,14,14H176a14,14,0,0,0,14-14V136A6,6,0,0,0,184,130Z"></path>
        </svg>
      </div>
    </PrimaryLinkButton>
  );
};

export default OpenButton;
