import classNames from 'classnames';
import { RuleType } from 'markdown-to-jsx';
import { useLayoutEffect } from 'react';
import { loadSourceInfosRequest } from '../../../../../actions/chatActions';
import { useAppDispatch } from '../../../../../hooks';
import markdownStyle from '../../../../Markdown.module.css';
import messageStyle from '../../../Message.module.css';
import MarkdownText from '../MarkdownText';

const SOURCE_REFERENCE_CLASS = 'source-info-reference';

type Props = {
  markdown: string;
  className: string;
  onLinkClick: (href: string) => void;
};

const MarkdownMessage = ({ markdown, className, onLinkClick }: Props) => {
  const dispatch = useAppDispatch();
  const counter = getFootnoteReferenceCounter();

  const handleOnSourceInfosKeyDown =
    (id: string) => (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      const key = event.key;
      if (
        key === 'ArrowDown' ||
        key === 'ArrowRight' ||
        key === 'Enter' ||
        key === ' ' ||
        key === 'Space'
      ) {
        event.stopPropagation();
        dispatch(loadSourceInfosRequest(id));
      }
    };

  const handleOnSourceInfosClick = (id: string) => () => {
    dispatch(loadSourceInfosRequest(id));
  };

  useLayoutEffect(() => {
    document
      .querySelectorAll(`.${SOURCE_REFERENCE_CLASS}`)
      .forEach((marker) => {
        const prevChar = marker.previousSibling;
        if (
          prevChar &&
          prevChar.nodeType === Node.TEXT_NODE &&
          prevChar.textContent?.endsWith(' ') &&
          prevChar.textContent?.trim().length
        ) {
          marker.classList.add(`${SOURCE_REFERENCE_CLASS}--before-space`);
        }
        const nextChar = marker.nextSibling;
        if (
          nextChar &&
          nextChar.nodeType === Node.TEXT_NODE &&
          nextChar.textContent?.startsWith(' ') &&
          nextChar.textContent?.trim().length
        ) {
          marker.classList.add(`${SOURCE_REFERENCE_CLASS}--after-space`);
        }
      });
  }, [markdown]);

  return (
    <div
      className={classNames(
        messageStyle.bubbleContent,
        markdownStyle.markdown,
        className
      )}
    >
      <MarkdownText
        markdown={markdown}
        onLinkClick={onLinkClick}
        markdownOptions={{
          renderRule(next, node, _renderChildren, state) {
            if (node.type === RuleType.footnoteReference) {
              return (
                <span
                  key={state.key}
                  className={classNames(
                    messageStyle.sourceInfoReference,
                    SOURCE_REFERENCE_CLASS
                  )}
                >
                  <a
                    type="button"
                    tabIndex={0}
                    onClick={handleOnSourceInfosClick(node.text)}
                    onKeyDown={handleOnSourceInfosKeyDown(node.text)}
                  >
                    {counter(node.text)}
                  </a>
                </span>
              );
            }
            return next();
          },
        }}
      />
    </div>
  );
};

const getFootnoteReferenceCounter = () => {
  const usedIds: string[] = [];
  const next = (id: string) => {
    const index = usedIds.indexOf(id);
    if (index > -1) {
      return index + 1;
    } else {
      usedIds.push(id);
      return usedIds.length;
    }
  };

  return next;
};

export default MarkdownMessage;
