import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';
import Button from './button';

const DefaultButton = ({
  className,
  children,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button
      className={classNames(styles.buttonDefault, className)}
      type="button"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default DefaultButton;
