import { closeSourceInfos } from '../../actions/chatActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import styles from './SourceInfos.module.css';
import Header from './header';
import LoadedSourceInfos from './loaded-source-infos';
import LoadingError from './loading-error';
import LoadingSourceInfos from './loading-source-infos';

const SourceInfos = () => {
  const dispatch = useAppDispatch();
  const sourceInfos = useAppSelector((state) => state.chat.sourceInfos);

  const handleOnClose = () => {
    dispatch(closeSourceInfos());
  };

  return sourceInfos ? (
    <div className={styles.sourceInfos}>
      <div className={styles.sourceDetails}>
        <Header
          sourceInfos={sourceInfos.type === 'loaded' ? sourceInfos : undefined}
          onClose={handleOnClose}
        />
      </div>

      {sourceInfos.type === 'loading' ? (
        <LoadingSourceInfos />
      ) : sourceInfos.type === 'loading-error' ? (
        <LoadingError />
      ) : (
        <LoadedSourceInfos sourceInfos={sourceInfos} />
      )}
    </div>
  ) : null;
};

export default SourceInfos;
