import classNames from 'classnames';
import styles from './Start.module.css';

type Props = {
  name: string;
  label: string;
  defaultValue: string | undefined;
  required: boolean;
  multiple: boolean;
  options: Record<string, string> | undefined;
  onChange: (value: string) => void;
};

const Select = ({
  name,
  label,
  defaultValue,
  required,
  multiple,
  options,
  onChange,
}: Props) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <label
      className={classNames(styles.select, 'chat__startScreen__select')}
      htmlFor={name}
    >
      <div className={classNames(styles.selectLabel)}>{label}</div>
      <div className={classNames(styles.selectWrapper)}>
        <select
          id={name}
          name={name}
          defaultValue={defaultValue}
          required={required}
          multiple={multiple}
          onChange={handleOnChange}
        >
          {Object.entries(options || {}).map(([key, value]) => (
            <option value={key} selected={value === defaultValue}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
};

export default Select;
