import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';
import Button from './button';

const PrimaryButton = ({
  className,
  children,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button className={classNames(styles.buttonPrimary, className)} {...rest}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
