import { QuickReply } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import Button from '../../../../components/button/button';
import styles from './QuickReplies.module.css';

type Props = {
  quickReplies: QuickReply[];
  onQuickReplyClick: (reply: QuickReply) => void;
};

const QuickReplies = ({ quickReplies, onQuickReplyClick }: Props) =>
  quickReplies.length ? (
    <div className={classNames(styles.quickReplies, 'chat__quickReplies')}>
      {quickReplies.map((reply) => (
        <Button
          key={reply.label}
          tabIndex={0}
          className={classNames(
            styles.quickReply,
            'chat__button',
            'chat__quickReply'
          )}
          onClick={() => onQuickReplyClick(reply)}
        >
          {reply.label}
        </Button>
      ))}
    </div>
  ) : null;

export default QuickReplies;
