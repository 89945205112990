import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { LoadedSourceInfos as LoadedSourceInfosType } from '../../types';
import markdownStyle from '../Markdown.module.css';
import styles from './SourceInfos.module.css';
import MarkdownLink from './markdown-link';
import OpenButton from './open-button';

type Props = {
  sourceInfos: LoadedSourceInfosType;
};

const LoadedSourceInfos = ({ sourceInfos }: Props) => {
  return (
    <>
      <div className={classNames(styles.sourceContent, markdownStyle.markdown)}>
        <Markdown
          options={{
            overrides: {
              a: MarkdownLink,
            },
          }}
        >
          {sourceInfos.content}
        </Markdown>
      </div>

      <OpenButton label={sourceInfos.title} url={sourceInfos.url} />
    </>
  );
};

export default LoadedSourceInfos;
