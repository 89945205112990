import useTranslation from '../../locales/useTranslation';
import styles from './SourceInfos.module.css';
import fixingError from './undraw_fixing-bugs.svg';

const LoadingError = () => {
  const translate = useTranslation();
  return (
    <div className={styles.loadingError}>
      <h1>{translate('source-infos.error.title')}</h1>
      <img src={fixingError} title={translate('source-infos.error.title')} />
      <p>{translate('source-infos.error.info')}</p>
    </div>
  );
};

export default LoadingError;
