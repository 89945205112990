const onButtonKeyDownHandler =
  (onClick: () => void) => (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const key = event.key;
    if (
      key === 'ArrowDown' ||
      key === 'ArrowRight' ||
      key === 'Enter' ||
      key === ' ' ||
      key === 'Space'
    ) {
      event.stopPropagation();
      onClick();
    }
  };

export default onButtonKeyDownHandler;
