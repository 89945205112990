import classNames from 'classnames';
import useTranslation from '../../locales/useTranslation';
import styles from './SourceInfos.module.css';

type Props = {
  onClick: () => void;
};

const CloseSourceInfosButton = ({ onClick }: Props) => {
  const translate = useTranslation();
  return (
    <button
      className={classNames(styles.closeSourceInfosButton, 'tooltip--bottom')}
      aria-label={translate('source-infos.close')}
      data-title={translate('source-infos.close')}
      tabIndex={0}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
      </svg>
    </button>
  );
};

export default CloseSourceInfosButton;
