import { UserType } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import { ReactNode, forwardRef, useRef } from 'react';
import useTranslation from '../../../../../locales/useTranslation';
import { ChatMessage, TranscriptItemReaction } from '../../../../../types';
import styles from '../../../Message.module.css';
import BotMessageActions from './BotMessageActions';

interface Props {
  message: ChatMessage;
  isAnimated: boolean;
  isLastMessage: boolean;
  isFirstMessageBySender: boolean;
  isLastMessageBySender: boolean;
  children: ReactNode;
  onReaction?: (reaction: TranscriptItemReaction) => void;
}

const BotMessage = forwardRef<HTMLLIElement, Props>(
  (
    {
      message,
      isAnimated,
      isLastMessage,
      isFirstMessageBySender,
      isLastMessageBySender,
      children,
      onReaction,
    },
    ref
  ) => {
    const translate = useTranslation();
    const botMessageRef = useRef<HTMLDivElement>(null);
    const isBot = message.sender.type === UserType.BOT;
    return (
      <li
        ref={ref}
        className={classNames(
          styles.message,
          styles.messageBot,
          isLastMessage && styles.lastMessage,
          isFirstMessageBySender && styles.messageBotFirst,
          isLastMessageBySender && styles.messageBotLast,
          'chat__bubbleContainer',
          isAnimated && styles.messageBotAnimated,
          isAnimated && 'chat__bubbleContainer--animated',
          isBot ? 'chat__botBubbleContainer' : 'chat__agentBubbleContainer',
          isFirstMessageBySender && 'chat__bubbleContainer--first',
          isLastMessageBySender && 'chat__bubbleContainer--last'
        )}
      >
        <img
          className={classNames(
            styles.avatar,
            isBot ? styles.botAvatar : styles.agentAvatar,
            'chat__avatar',
            isBot ? 'chat__botAvatar' : 'chat__agentAvatar'
          )}
          src={`${message.sender.avatar}`}
          alt={isBot ? 'Bot' : 'Agent'}
          referrerPolicy="no-referrer"
        />

        <div
          ref={botMessageRef}
          className={classNames(
            styles.bubble,
            styles.bubbleLeft,
            'chat__bubble',
            isBot ? 'chat__botBubble' : 'chat__agentBubble'
          )}
          tabIndex={0}
          aria-label={translate(
            isBot ? 'bot-bubble.label' : 'agent-bubble.label'
          )}
        >
          <div className={classNames(styles.leftText)}>{children}</div>
        </div>

        {onReaction && (
          <BotMessageActions
            alwaysVisible={isLastMessage && isLastMessageBySender}
            reaction={message.reaction}
            botMessageRef={botMessageRef}
            onReaction={onReaction}
          />
        )}
      </li>
    );
  }
);

export default BotMessage;
