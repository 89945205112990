import { ActionTypes, GuestUser, TextMessage } from '@botfabrik/engine-domain';
import { AnyAction } from 'redux';
import { v4 as uuid } from 'uuid';
import * as ClientActions from '../actions';
import { RESET_CHAT_STATE } from '../actions/chatActions';
import { START, TERMINATE_SESSION } from '../actions/connectionActions';
import { ChatMessage, ChatState, ConversationRatingState } from '../types';

const chatReducer = (state = initialState, action: AnyAction): ChatState => {
  switch (action.type) {
    case RESET_CHAT_STATE: {
      return initialState;
    }

    case START: {
      sendToParentPage({ type: 'webclient.chat.started' });
      return state;
    }

    case TERMINATE_SESSION: {
      sendToParentPage({ type: 'webclient.chat.ended' });
      return state;
    }

    case ActionTypes.TYPING_ON: {
      return Object.assign({}, state, {
        isBotTyping: true,
      });
    }
    case ActionTypes.TYPING_OFF: {
      return Object.assign({}, state, {
        isBotTyping: false,
      });
    }
    case ActionTypes.MESSAGE_FROM_GUEST:
    case ActionTypes.MESSAGE_WITHOUT_ANALYSIS_FROM_GUEST:
      return appendMessageFromGuestToState(state, action.message);

    case ActionTypes.MESSAGE_TO_GUEST: {
      // Informiere das Top Window (z.B. unser Wordpress-Blog) über das eintreffen einer neuen Nachricht
      sendToParentPage(action);

      // id hinzufügen, damit wir nicht zweimal die selbe Meldung im State haben
      const message: ChatMessage = Object.assign({}, action.message, {
        _id: action.meta.actionId,
      });
      const alreadyAdded =
        state.messages.filter((m: any) => m._id === action.meta.actionId)
          .length > 0;

      return Object.assign({}, state, {
        isBotTyping: false,
        messages: alreadyAdded
          ? state.messages
          : state.messages.concat([message]),
        quickReplies: message.quickReplies ? message.quickReplies : [],
      });
    }

    case ClientActions.RESTORE_PREVIOUS_CHAT_MESSAGES: {
      const messages = action.messages;
      const lastMessage = messages.length
        ? messages[messages.length - 1]
        : undefined;
      return Object.assign({}, state, {
        isBotTyping: false,
        messages,
        quickReplies: lastMessage?.quickReplies || [],
      });
    }

    case ClientActions.MESSAGE_TO_PARENT_PAGE: {
      sendToParentPage(action.message);
      return state;
    }

    case ActionTypes.EVENT_TO_GUEST: {
      sendToParentPage(action.event);
      return state;
    }

    case ActionTypes.DRAWER_OPEN: {
      return Object.assign({}, state, {
        drawerContentUrl: action.payload.parameters.contentUrl,
      });
    }

    case ActionTypes.DRAWER_CLOSE: {
      return Object.assign({}, state, {
        drawerContentUrl: undefined,
      });
    }

    case ActionTypes.GUEST_LOCALE_CHANGED: {
      return Object.assign({}, state, {
        locale: action.payload.locale,
      });
    }

    case ClientActions.EXPAND_WINDOW: {
      sendToParentPage(action);
      return state;
    }

    case ClientActions.CLOSE_WINDOW: {
      sendToParentPage(action);
      return state;
    }

    case ClientActions.ENABLE_SPEECH_SUPPORT: {
      return Object.assign({}, state, { speechSupport: true });
    }

    case ClientActions.RECEIVE_SPEECH_TRANSCRIPTION: {
      const msg = new TextMessage(action.transcription, new GuestUser());
      const chatMsg: ChatMessage = Object.assign({}, msg, {
        _id: uuid(),
      });
      return appendMessageFromGuestToState(state, chatMsg);
    }

    case ClientActions.ENABLE_TRANSCRIPT_EXPORT: {
      return Object.assign({}, state, {
        transcriptExportEnabled: action.methods,
      });
    }

    case ClientActions.SHOW_GENERAL_CONDITIONS: {
      return Object.assign({}, state, { showGeneralConditions: true });
    }

    case ActionTypes.TRANSCRIPT_ITEM_GUEST_REACTION: {
      const msgs = state.messages.map((m) => {
        if (m._id === action.payload.actionId) {
          return Object.assign({}, m, {
            reaction: action.payload.reaction,
          });
        } else {
          return m;
        }
      });
      return Object.assign({}, state, { messages: msgs });
    }

    case ClientActions.ENABLE_CONVERSATION_RATING: {
      const conversationRating: ConversationRatingState = {
        enabled: true,
        rating: action.payload.rating,
      };
      return Object.assign({}, state, { conversationRating });
    }

    case ActionTypes.CONVERSATION_RATING_FROM_GUEST: {
      const conversationRating: ConversationRatingState = {
        ...state.conversationRating,
        rating: action.payload,
      };
      return Object.assign({}, state, { conversationRating });
    }

    case ClientActions.SET_COMMANDS: {
      return { ...state, commands: action.payload.commands };
    }

    case ClientActions.LOAD_SOURCE_INFOS_REQUEST: {
      return {
        ...state,
        sourceInfos: { type: 'loading', chunkId: action.payload.chunkId },
      };
    }

    case ClientActions.LOAD_SOURCE_INFOS_ERROR: {
      return {
        ...state,
        sourceInfos: { type: 'loading-error' },
      };
    }

    case ClientActions.LOAD_SOURCE_INFOS_RESPONSE: {
      return {
        ...state,
        sourceInfos: {
          type: 'loaded',
          chunkId: action.payload.chunkId,
          knowledgeBaseType: action.payload.knowledgeBaseType,
          title: action.payload.title,
          content: action.payload.content,
          url: action.payload.url,
        },
      };
    }

    case ClientActions.CLOSE_SOURCE_INFOS: {
      return { ...state, sourceInfos: undefined };
    }

    default:
      return state;
  }
};

const appendMessageFromGuestToState = (
  state: ChatState,
  message: ChatMessage
) => {
  const lastMessage = state.messages[state.messages.length - 1];
  const sameText = (lastMessage as any)?.text === (message as any).text;
  if (
    lastMessage &&
    lastMessage.sender.type === message.sender.type &&
    sameText
  ) {
    return state;
  }

  return Object.assign({}, state, {
    messages: state.messages.concat([message]),
    quickReplies: [],
  });
};

const sendToParentPage = (action: any) => {
  if (isIframe()) {
    window.top?.postMessage(action, '*');
  } else if (action.type === 'webclient.change.url') {
    window.open(action.url);
  }
};

const isIframe = () =>
  typeof window !== 'undefined' && window.self !== window.top;

const initialState: ChatState = {
  messages: [],
  quickReplies: [],
  isBotTyping: false,
  speechSupport: false,
  transcriptExportEnabled: {
    pdf: false,
    email: false,
  },
  showGeneralConditions: false,
  conversationRating: {
    enabled: false,
  },
  commands: [],
};

export default chatReducer;
