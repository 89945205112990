import { flatten } from 'flat';
import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import it from './it.json';

const supportedLocales = ['de', 'en', 'fr', 'it'];

interface IntlState {
  locale: string;
  messages: any;
}

export const initialState: IntlState = {
  locale: 'de',
  messages: de,
};

export const mergeMessages = (
  language: string,
  customTranslations: Record<string, any>
): Record<string, string> => {
  if (supportedLocales.includes(language)) {
    const defaultMessages = getMessagesByLanguage(language);
    const translationObject = flatten<
      Record<string, any>,
      Record<string, string>
    >(customTranslations);

    const merged = { ...defaultMessages, ...translationObject };
    return merged;
  }
  return customTranslations;
};

const getMessagesByLanguage = (language: string) => {
  switch (language.toString()) {
    case 'en':
      return en;
    case 'fr':
      return fr;
    case 'it':
      return it;
    default:
      return de;
  }
};
