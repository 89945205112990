import classNames from 'classnames';
import { RefObject, useCallback, useState } from 'react';
import useTranslation from '../../../../../locales/useTranslation';
import { type TranscriptItemReaction } from '../../../../../types';
import styles from './BotMessageActions.module.css';

type Props = {
  alwaysVisible: boolean;
  reaction: TranscriptItemReaction;
  botMessageRef: RefObject<HTMLDivElement>;
  onReaction: (reaction: TranscriptItemReaction) => void;
};

const BotMessageActions = ({
  alwaysVisible,
  reaction,
  botMessageRef,
  onReaction,
}: Props) => {
  const translate = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const handleOnReaction = (reaction: 'GOOD' | 'BAD' | null) => () =>
    onReaction(reaction);

  const handleOnCopy = useCallback(async () => {
    if (botMessageRef.current) {
      try {
        setIsCopied(true);
        const htmlContent = botMessageRef.current.innerHTML;
        const textContent = botMessageRef.current.innerText;
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
            'text/plain': new Blob([textContent], { type: 'text/plain' }),
          }),
        ]);
        await new Promise((resolve) => setTimeout(resolve, 1_500));
      } finally {
        setIsCopied(false);
      }
    }
  }, [botMessageRef]);

  return (
    <div
      className={classNames(
        styles.botMessageActions,
        alwaysVisible && styles.alwaysVisible,
        reaction && styles.reported
      )}
    >
      <button
        className={classNames(
          styles.action,
          reaction === 'GOOD' && styles.reported,
          'tooltip--right'
        )}
        tabIndex={0}
        aria-label={translate(
          reaction === 'GOOD'
            ? 'bot-bubble.reaction.remove'
            : 'bot-bubble.reaction.good'
        )}
        data-title={translate(
          reaction === 'GOOD'
            ? 'bot-bubble.reaction.remove'
            : 'bot-bubble.reaction.good'
        )}
        onClick={handleOnReaction(reaction === 'GOOD' ? null : 'GOOD')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          {reaction === 'GOOD' ? (
            <path d="M234,80.12A24,24,0,0,0,216,72H160V56a40,40,0,0,0-40-40,8,8,0,0,0-7.16,4.42L75.06,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H204a24,24,0,0,0,23.82-21l12-96A24,24,0,0,0,234,80.12ZM32,112H72v88H32Z"></path>
          ) : (
            <path d="M234,80.12A24,24,0,0,0,216,72H160V56a40,40,0,0,0-40-40,8,8,0,0,0-7.16,4.42L75.06,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H204a24,24,0,0,0,23.82-21l12-96A24,24,0,0,0,234,80.12ZM32,112H72v88H32ZM223.94,97l-12,96a8,8,0,0,1-7.94,7H88V105.89l36.71-73.43A24,24,0,0,1,144,56V80a8,8,0,0,0,8,8h64a8,8,0,0,1,7.94,9Z"></path>
          )}
        </svg>
      </button>

      <button
        className={classNames(
          styles.action,
          reaction === 'BAD' && styles.reported,
          'tooltip--right'
        )}
        tabIndex={0}
        aria-label={translate(
          reaction === 'BAD'
            ? 'bot-bubble.reaction.remove'
            : 'bot-bubble.reaction.bad'
        )}
        data-title={translate(
          reaction === 'BAD'
            ? 'bot-bubble.reaction.remove'
            : 'bot-bubble.reaction.bad'
        )}
        onClick={handleOnReaction(reaction === 'BAD' ? null : 'BAD')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          {reaction === 'BAD' ? (
            <path d="M239.82,157l-12-96A24,24,0,0,0,204,40H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75.06l37.78,75.58A8,8,0,0,0,120,240a40,40,0,0,0,40-40V184h56a24,24,0,0,0,23.82-27ZM72,144H32V56H72Z"></path>
          ) : (
            <path d="M239.82,157l-12-96A24,24,0,0,0,204,40H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75.06l37.78,75.58A8,8,0,0,0,120,240a40,40,0,0,0,40-40V184h56a24,24,0,0,0,23.82-27ZM72,144H32V56H72Zm150,21.29a7.88,7.88,0,0,1-6,2.71H152a8,8,0,0,0-8,8v24a24,24,0,0,1-19.29,23.54L88,150.11V56H204a8,8,0,0,1,7.94,7l12,96A7.87,7.87,0,0,1,222,165.29Z"></path>
          )}
        </svg>
      </button>

      <button
        className={classNames(styles.action, 'tooltip--right')}
        tabIndex={0}
        aria-label={translate('bot-bubble.copy')}
        data-title={translate('bot-bubble.copy')}
        onClick={handleOnCopy}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          {isCopied ? (
            <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
          ) : (
            <path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path>
          )}
        </svg>
      </button>
    </div>
  );
};

export default BotMessageActions;
