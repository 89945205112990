import { QuickReply } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ConversationRatingState } from '../../../types';
import styles from './InputContainer.module.css';
import BlockUserInput from './block-user-input';
import CommandsDropdown from './commands/commands-dropdown';
import CommandsSelections from './commands/commands-selections';
import ConversationRating from './conversation-rating';
import Input from './input';
import QuickReplies from './quick-replies';

const MIN_USER_BLOCKED_TIME = 5_000;
const MIN_TIME_BETWEEN_USER_REQUESTS = 1_000;

interface Props {
  numberOfMessagesFromGuest: number;
  isBotTyping: boolean;
  quickReplies: QuickReply[];
  speechSupport: boolean;
  transcriptExportEnabled: boolean;
  conversationRating: ConversationRatingState;
  showRatingOverlay: boolean;
  commands: string[];
  setShowRatingOverlay: (show: boolean) => void;
  onTextInput: (text: string) => void;
  onRate: (rating: number, description: string) => void;
  onQuickReplyClick: (reply: QuickReply) => void;
  onAudioRecorded: (blob: Blob) => void;
  onOpenTranscriptExportModal: () => void;
}

const UserInput = ({
  isBotTyping,
  quickReplies,
  speechSupport,
  transcriptExportEnabled,
  conversationRating,
  numberOfMessagesFromGuest,
  showRatingOverlay,
  commands,
  setShowRatingOverlay,
  onTextInput,
  onRate,
  onQuickReplyClick,
  onAudioRecorded,
  onOpenTranscriptExportModal,
}: Props) => {
  const [textInput, setTextInput] = useState('');
  const [blockUserInput, setBlockUserInput] = useState(false);
  const [commandsVisible, setCommandsVisible] = useState(false);
  const blockUserInputStartAt = useRef<number>();
  const lastUserInputAt = useRef<number>(0);

  useEffect(() => {
    if (blockUserInput && !isBotTyping) {
      const displayTime = blockUserInputStartAt.current
        ? Date.now() - blockUserInputStartAt.current
        : 0;

      if (displayTime > MIN_USER_BLOCKED_TIME) {
        setBlockUserInput(false);
      } else {
        setTimeout(() => {
          setBlockUserInput(false);
        }, MIN_USER_BLOCKED_TIME - displayTime);
      }
    }
  }, [blockUserInput, isBotTyping]);

  useEffect(() => {
    if (blockUserInput) {
      blockUserInputStartAt.current = Date.now();
    }
  }, [blockUserInput]);

  const handleOnTextInputSubmit = (text: string): boolean => {
    const lastUserInputWasShortlyBefore =
      Date.now() - lastUserInputAt.current < MIN_TIME_BETWEEN_USER_REQUESTS;
    if (commandsVisible) {
      return false;
    } else if (isBotTyping || lastUserInputWasShortlyBefore) {
      setBlockUserInput(true);
      return false;
    } else {
      onTextInput(text);
      setTextInput('');
      lastUserInputAt.current = Date.now();
      return true;
    }
  };

  const handleOnSelectCommand = (command: string) => {
    setTextInput(command + ' ');
  };

  return (
    <div className={classNames(styles.inputContainer)}>
      <QuickReplies
        quickReplies={quickReplies}
        onQuickReplyClick={onQuickReplyClick}
      />

      <BlockUserInput visible={blockUserInput} />

      {!blockUserInput && (
        <ConversationRating
          conversationRating={conversationRating}
          numberOfMessagesFromGuest={numberOfMessagesFromGuest}
          onRate={onRate}
          showRatingOverlay={showRatingOverlay}
          setShowRatingOverlay={setShowRatingOverlay}
        />
      )}

      {!!commands?.length && (
        <CommandsDropdown
          text={textInput}
          commands={commands}
          onSelectCommand={handleOnSelectCommand}
          onVisibilityChange={setCommandsVisible}
        />
      )}

      <Input
        textInput={textInput}
        speechSupport={speechSupport}
        transcriptExportEnabled={transcriptExportEnabled}
        disabled={blockUserInput}
        commandsVisible={commandsVisible}
        onTextInput={setTextInput}
        onTextInputSubmit={handleOnTextInputSubmit}
        onOpenTranscriptExportModal={onOpenTranscriptExportModal}
        onAudioRecorded={onAudioRecorded}
      />

      {!!commands?.length && (
        <CommandsSelections
          commands={commands}
          onSelectCommand={setTextInput}
        />
      )}
    </div>
  );
};

export default UserInput;
